




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Detail from './detail.vue'
@Component({
    components: {
        Detail,
    },
})
export default class TemplateSelect extends Vue {
    visible = false
    onTrigger() {
        this.visible = true;
    }
    close() {
        this.visible = false;
    }
    onSelect(value: any) {
        this.close()
        this.$emit('select', value)
    }
}
