import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'

// Vue Router 路由管理器
// route 路由（器），router 路由管理器
const routes = [
    {
        // 对应当前路由的路径，总是解析成绝对路径
        path: '/goods',
        // 命名路由，用于链接路由
        name: 'goods',
        // meta 提供有关当前路由的元信息
        meta: {title: '产品管理'},
        // 由当前路由重定向到其他路由
        // 由 '/goods' 重定向到 '/goods/lists'
        // 即点击“商品”时，直接到“商品管理”界面
        redirect: '/goods/lists',
        // 每个路由映射一个组件
        // 即 goods 路由对应 Main 组件
        component: Main,
        // 由 children 配置嵌套路由
        children: [
            {
                path: '/goods/lists',
                name: 'goods_lists',
                meta: {
                    title: '商品管理',
                    parentPath: '/goods',
                    icon: 'icon_goods',
                    permission: ['view'],
                },
                component: () => import('@/views/goods/lists.vue'),
            },
            {
                path: '/goods/release',
                name: 'goods_release',
                meta: {
                    hidden: true,
                    title: '新增商品',
                    parentPath: '/goods',
                    prevPath: '/goods/lists',
                },
                component: () => import('@/views/goods/release.vue'),
            },
            {
                path: '/goods/category',
                name: 'goods_category',
                meta: {
                    title: '分类管理',
                    parentPath: '/goods',
                    icon: 'icon_sort',
                    permission: ['view'],
                },
                component: () => import('@/views/goods/category.vue'),
            },
            {
                path: '/goods/category_edit',
                name: 'category_edit',
                meta: {
                    hidden: true,
                    title: '新增商品分类',
                    parentPath: '/goods',
                    prevPath: '/goods/category',
                },
                component: () => import('@/views/goods/category_edit.vue'),
            },
            {
                path: '/goods/brand',
                name: 'goods_brand',
                meta: {
                    title: '品牌管理',
                    parentPath: '/goods',
                    icon: 'icon_pinpai',
                    permission: ['view'],
                },
                component: () => import('@/views/goods/brand.vue'),
            },
            {
                path: '/goods/brand_edit',
                name: 'brand_edit',
                meta: {
                    hidden: true,
                    title: '新增品牌',
                    parentPath: '/goods',
                    prevPath: '/goods/brand',
                },
                component: () => import('@/views/goods/brand_edit.vue'),
            },
            {
                path: '/goods/unit',
                name: 'goods_unit',
                meta: {
                    title: '商品单位',
                    icon: 'icon_danwei',
                    parentPath: '/goods',
                    permission: ['view'],
                },
                component: () => import('@/views/goods/unit.vue'),
            },
            {
                path: '/goods/supplier',
                name: 'goods_supplier',
                meta: {
                    title: '供应商',
                    parentPath: '/goods',
                    icon: 'icon_gongyingshang',
                },
                redirect: '/goods/supplier/lists',
                component: Blank,
                children: [
                    {
                        path: '/goods/supplier/lists',
                        name: 'supplier_lists',
                        meta: {
                            title: '供应商管理',
                            parentPath: '/goods',
                            permission: ['view'],
                        },
                        component: () => import('@/views/goods/supplier/lists.vue'),
                    },
                    {
                        path: '/goods/supplier/edit',
                        name: 'supplier_edit',
                        meta: {
                            hidden: true,
                            title: '新增供应商',
                            parentPath: '/goods',
                            prevPath: '/goods/supplier/lists',
                        },
                        component: () => import('@/views/goods/supplier/edit.vue'),
                    },
                    {
                        path: '/goods/supplier/category',
                        name: 'supplier_category',
                        meta: {
                            title: '供应商分类',
                            parentPath: '/goods',
                            permission: ['view'],
                        },
                        component: () => import('@/views/goods/supplier/category.vue'),
                    },
                ],
            },
            {
                path: '/goods/evaluation',
                name: 'goods_evaluation',
                meta: {
                    title: '评价管理',
                    parentPath: '/goods',
                    icon: 'icon_pingjia',
                    permission: ['view'],
                },
                component: () => import('@/views/goods/evaluation.vue'),
            },
        ],
    },
]

export default routes
