import Main from '@/layout/main.vue'

const routes = [
    {
        path: '/content',
        name: 'content',
        meta: {title: '7.9内容运营管理'},
        redirect: '/content/workbench',
        component: Main,
        children: [
            {
                path: '/content/workbench',
                name: 'content_workbench',
                meta: {
                    title: '工作台',
                    parentPath: '/content',
                    icon: 'icon_goods',
                    permission: ['view'],
                },
                component: () => import('@/views/content/workbench.vue'),
            },
            {
                path: '/content/topic',
                meta: {
                    title: '主题管理',
                    icon: 'icon_caiwu_tixian',
                    parentPath: '/content',
                    permission: ['view'],
                },
                component: () => import('@/views/content/topic_manage.vue'),
            },
            {
                path: '/content/material',
                name: 'material_manage',
                meta: {
                    title: '素材管理',
                    icon: 'icon_dianpu_sucai',
                    parentPath: '/content',
                    permission: ['view'],
                },
                component: () => import('@/views/content/material_manage.vue'),
            },
            {
                path: '/content/new_material',
                name: 'new_material',
                meta: {
                    title: '新增素材',
                    parentPath: '/content',
                    hidden: true,
                    prevPath: '/content/material'
                },
                component: () => import('@/views/content/new_material.vue'),
            },
            {
                path: '/content/new_topic/:data',
                name: 'new_topic',
                meta: {
                    title: '新增主题',
                    parentPath: '/content',
                    hidden: true,
                    prevPath: '/goods/topic'
                },
                component: () => import('@/views/content/new_topic.vue'),
            }
        ]
    }]
export default routes
