import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [
    {
        path: '/data',
        name: 'data',
        meta: { title: '数据分析' },
        redirect: '/data/flow_analysis',
        component: Main,
        children: [{
            path: '/data/flow_analysis',
            name: 'flow_analysis',
            meta: {
                title: '流量分析',
                parentPath: '/data',
                icon: 'icon_shuju_liuliang',
                permission: ['view']
            },
            component: () => import('@/views/data/flow_analysis.vue'),
        }, {
            path: '/data/user',
            name: 'data_user',
            meta: {
                title: '用户分析',
                parentPath: '/data',
                icon: 'icon_shuju',
                permission: ['view']
            },
            component: () => import('@/views/data/user.vue')
        }, {
            path: '/data/transaction',
            name: 'transaction',
            meta: {
                title: '交易分析',
                parentPath: '/data',
                icon: 'icon_shuju',
                permission: ['view']
            },
            component: () => import('@/views/data/transaction.vue')
        }, {
            path: '/data/goods/goods',
            name: 'goods_goods',
            meta: {
                title: '商品数据',
                parentPath: '/data',
                icon: 'icon_shuju_liuliang',
                permission: ['view']
            },
            component: Blank,
            redirect: '/data/goods/goods',
            children: [{
                path: '/data/goods/goods',
                name: 'data_goods_goods',
                meta: {
                    title: '商品分析',
                    parentPath: '/data',
                    permission: ['view']
                },
                component: () => import('@/views/data/goods/goods.vue')
            }, {
                path: '/data/goods/ranking',
                name: 'goods_ranking',
                meta: {
                    title: '商品排行',
                    parentPath: '/data',
                    permission: ['view']
                },
                component: () => import('@/views/data/goods/ranking.vue')
            }]
        },
            //用户反馈
            {
                path: '/data/feedback/transaction',
                name: 'feedback',
                meta: {
                    title: '用户反馈',
                    parentPath: '/data',
                    icon: 'icon_shuju',
                    permission: ['view']
                },
                component: Blank,
                redirect: '/data/feedback/transaction',
                children:[
                    {
                        path: '/data/feedback/transaction',
                        name: 'feedback_transaction',
                        meta: {
                            title: '用户反馈列表',
                            parentPath: '/data',
                            permission: ['view']
                        },
                        component: () => import('@/views/data/feedback/transaction.vue')
                    },
                    {
                        path: '/data/feedback/brand',
                        name: 'feedback_brand',
                        meta: {
                            title: '问题类型配置',
                            parentPath: '/data',
                            permission: ['view']
                        },
                        component: () => import('@/views/data/feedback/brand.vue')
                    }
                    
                ],

               
            },
            {
                path: '/data/feedback/brand',
                name: 'data_feedback_brand',
                meta: {
                    hidden: true,
                    title: '用户反馈类型',
                    parentPath: '/data/feedback',
                },
                component: () => import('@/views/data/feedback/brand.vue'),
            },
            {
                path: '/data/feedback/brand_edit',
                name: 'feedback_edit',
                meta: {
                    hidden: true,
                    title: '新增问题反馈类型',
                    parentPath: '/data/feedback',

                },
                component: () => import('@/views/data/feedback/brand_edit.vue'),
            },
        ]
    }
]

export default routes
