








import { Component, Vue } from 'vue-property-decorator'
import Perm from '@/components/layout/perm.vue'
@Component({
  components: {
    Perm,
  },
})
export default class Layout extends Vue {}
