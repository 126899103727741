import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'

const routes = [
    {
        path: '/marketing',
        name: 'marketing',
        meta: {title: '应用管理'},
        redirect: '/marketing/index',
        component: Main,
        children: [
            {
                path: '/marketing/index',
                name: 'marketing_index',
                meta: {
                    // title: '营销中心',
                    title: '应用中心',
                    parentPath: '/marketing',
                    icon: 'icon_yingxiaowf',
                    permission: ['view'],
                },
                component: () => import('@/views/marketing/index.vue'),
            },
            // {
            //   path: '/marketing/app',
            //   name: 'marketing_app',
            //   meta: {
            //     title: '应用中心',
            //     parentPath: '/marketing',
            //     icon: 'icon_yingyongcenter',
            //     permission: ['view'],
            //   },
            //   component: () => import('@/views/marketing/app.vue'),
            // },
        ],
    },

    // 优惠券
    {
        path: '/coupon',
        name: 'coupon',
        meta: {
            title: '优惠券',
            hidden: true,
            moduleName: 'coupon',
        },
        redirect: '/coupon/lists',
        component: Main,
        children: [
            {
                path: '/coupon/survey',
                name: 'coupon_survey',
                meta: {
                    hidden: true,
                    title: '优惠券概览',
                    parentPath: '/marketing',
                    icon: 'icon_coupons_data',
                    moduleName: 'coupon',
                    permission: ['view'],
                },
                component: () => import('@/views/marketing/coupon/survey.vue'),
            },
            {
                path: '/coupon/lists',
                name: 'coupon_lists',
                meta: {
                    title: '优惠券',
                    parentPath: '/marketing',
                    icon: 'icon_coupons',
                    moduleName: 'coupon',
                    permission: ['view'],
                },
                component: () => import('@/views/marketing/coupon/lists.vue'),
            },
            {
                path: '/coupon/receive_record',
                name: 'receive_record',
                meta: {
                    title: '领取记录',
                    parentPath: '/marketing',
                    icon: 'icon_order_guanli',
                    moduleName: 'coupon',
                    permission: ['view'],
                },
                component: () => import('@/views/marketing/coupon/receive_record.vue'),
            },
            {
                path: '/coupon/edit',
                name: 'coupon_edit',
                meta: {
                    hidden: true,
                    title: '新增编辑优惠券',
                    parentPath: '/marketing',
                    prevPath: '/coupon/lists',
                    moduleName: 'coupon',
                },
                component: () => import('@/views/marketing/coupon/edit.vue'),
            },
            // {
            //   path: '/coupon/grant',
            //   name: 'coupon_grant',
            //   meta: {
            //     hidden: true,
            //     title: '发放优惠券',
            //     parentPath: '/marketing',
            //     prevPath: '/coupon/lists',
            //     moduleName: 'coupon',
            //   },
            //   component: () => import('@/views/marketing/coupon/grant.vue'),
            // },
        ],
    },

    // 分销
    // {
    //   path: '/distribution',
    //   name: 'distribution',
    //   meta: {
    //     title: '分销',
    //     hidden: true,
    //     moduleName: 'distribution',
    //   },
    //   redirect: '/distribution/survey',
    //   component: Main,
    //   children: [
    //     {
    //       path: '/distribution/survey',
    //       name: 'survey',
    //       meta: {
    //         title: '分销概览',
    //         parentPath: '/marketing',
    //         moduleName: 'distribution',
    //         icon: 'icon_fenxiao_data',
    //         permission: ['view'],
    //       },
    //       component: () => import('@/views/marketing/distribution/survey.vue'),
    //     },
    //     {
    //       path: '/distribution/store',
    //       name: 'store',
    //       meta: {
    //         title: '分销商',
    //         parentPath: '/marketing',
    //         moduleName: 'distribution',
    //         icon: 'icon_fenxiao_member',
    //         permission: ['view'],
    //       },
    //       children: [
    //         {
    //           path: '/distribution/store',
    //           name: 'distribution_store',
    //           meta: {
    //             title: '分销商',
    //             parentPath: '/marketing',
    //             moduleName: 'distribution',
    //             permission: ['view'],
    //           },
    //           component: () =>
    //             import('@/views/marketing/distribution/store/store.vue'),
    //         },
    //         {
    //           path: '/distribution/store_detail',
    //           name: 'distribution_store_detail',
    //           meta: {
    //             hidden: true,
    //             title: '分销商详情',
    //             parentPath: '/marketing',
    //             prevPath: '/distribution/store',
    //             moduleName: 'distribution',
    //           },
    //           component: () =>
    //             import('@/views/marketing/distribution/store/store_detail.vue'),
    //         },
    //         {
    //           path: '/distribution/distribution_apply',
    //           name: 'distribution_apply',
    //           meta: {
    //             title: '分销申请',
    //             parentPath: '/marketing',
    //             prevPath: '/distribution/distribution_apply',
    //             moduleName: 'distribution',
    //             permission: ['view'],
    //           },
    //           component: () =>
    //             import('@/views/marketing/distribution/store/apply.vue'),
    //         },
    //         {
    //           path: '/distribution/distribution_apply_details',
    //           name: 'distribution_apply_details',
    //           meta: {
    //             hidden: true,
    //             title: '分销申请详情',
    //             parentPath: '/marketing',
    //             prevPath: '/distribution/distribution_apply',
    //             moduleName: 'distribution',
    //           },
    //           component: () =>
    //             import('@/views/marketing/distribution/store/apply_details.vue'),
    //         },
    //         {
    //           path: '/distribution/lower_level_list',
    //           name: 'lower_level_list',
    //           meta: {
    //             hidden: true,
    //             title: '下级列表',
    //             parentPath: '/marketing',
    //             prevPath: '/distribution/store',
    //             moduleName: 'distribution',
    //           },
    //           component: () =>
    //             import(
    //               '@/views/marketing/distribution/store/lower_level_list.vue'
    //             ),
    //         },
    //       ],
    //       component: Blank,
    //     },
    //     {
    //       path: '/distribution/distribution_goods',
    //       name: 'distribution_goods',
    //       meta: {
    //         title: '分销商品',
    //         parentPath: '/marketing',
    //         icon: 'icon_fenxiao_goods',
    //         moduleName: 'distribution',
    //         permission: ['view'],
    //       },
    //       component: () =>
    //         import('@/views/marketing/distribution/distribution_goods.vue'),
    //     },
    //     {
    //       path: '/distribution/distribution_goods_edit',
    //       name: 'distribution_goods_edit',
    //       meta: {
    //         hidden: true,
    //         title: '设置分销佣金',
    //         parentPath: '/marketing',
    //         prevPath: '/distribution/distribution_goods',
    //         moduleName: 'distribution',
    //       },
    //       component: () =>
    //         import('@/views/marketing/distribution/distribution_goods_edit.vue'),
    //     },
    //     {
    //       path: '/distribution/distribution_orders',
    //       name: 'distribution_orders',
    //       meta: {
    //         title: '分销订单',
    //         parentPath: '/marketing',
    //         icon: 'icon_dianpu_xiangqing',
    //         moduleName: 'distribution',
    //         permission: ['view'],
    //       },
    //       component: () =>
    //         import('@/views/marketing/distribution/distribution_orders.vue'),
    //     },
    //     {
    //       path: '/distribution/distribution_grade',
    //       name: 'distribution_grade',
    //       meta: {
    //         title: '分销等级',
    //         parentPath: '/marketing',
    //         icon: 'icon_fenxiao_grade',
    //         moduleName: 'distribution',
    //         permission: ['view'],
    //       },
    //       component: () =>
    //         import('@/views/marketing/distribution/distribution_grade.vue'),
    //     },
    //     {
    //       path: '/distribution/distribution_grade_edit',
    //       name: 'distribution_grade_edit',
    //       meta: {
    //         hidden: true,
    //         title: '分销等级编辑',
    //         parentPath: '/marketing',
    //         prevPath: '/distribution/distribution_grade',
    //         moduleName: 'distribution',
    //       },
    //       component: () =>
    //         import('@/views/marketing/distribution/distribution_grade_edit.vue'),
    //     },
    //     {
    //       path: 'distribution/setting',
    //       name: 'setting',
    //       meta: {
    //         title: '分销设置',
    //         parentPath: '/marketing',
    //         moduleName: 'distribution',
    //         icon: 'icon_fenxiao_set',
    //       },
    //       children: [
    //         {
    //           path: '/distribution/base_setting',
    //           name: 'base_setting',
    //           meta: {
    //             title: '基础设置',
    //             parentPath: '/marketing',
    //             moduleName: 'distribution',
    //             permission: ['view'],
    //           },
    //           component: () =>
    //             import('@/views/marketing/distribution/setting/base_setting.vue'),
    //         },
    //         {
    //           path: '/distribution/result_setting',
    //           name: 'result_setting',
    //           meta: {
    //             title: '结算设置',
    //             parentPath: '/marketing',
    //             moduleName: 'distribution',
    //             permission: ['view'],
    //           },
    //           component: () =>
    //             import(
    //               '@/views/marketing/distribution/setting/result_setting.vue'
    //             ),
    //         },
    //       ],
    //       component: Blank,
    //     },
    //   ],
    // },

    // 用户储值
    {
        path: '/recharge',
        name: 'recharge',
        meta: {
            title: '充值概览',
            hidden: true,
            moduleName: 'recharge',
        },
        redirect: '/recharge/survey',
        component: Main,
        children: [
            {
                path: '/recharge/survey',
                name: 'survey',
                meta: {
                    title: '充值概览',
                    parentPath: '/marketing',
                    moduleName: 'recharge',
                    icon: 'icon_pintuan_data',
                    permission: ['view'],
                },
                component: () => import('@/views/marketing/recharge/survey.vue'),
            },
            {
                path: '/recharge/rule',
                name: 'rule',
                meta: {
                    title: '充值规则',
                    parentPath: '/marketing',
                    moduleName: 'recharge',
                    icon: 'icon_caiwu_yue',
                    permission: ['view'],
                },
                component: () => import('@/views/marketing/recharge/rule.vue'),
            },
            {
                path: '/recharge/record',
                name: 'record',
                meta: {
                    title: '充值记录',
                    parentPath: '/marketing',
                    moduleName: 'recharge',
                    icon: 'icon_order_guanli',
                    permission: ['view'],
                },
                component: () => import('@/views/marketing/recharge/record.vue'),
            },
        ],
    },

    // ----日历签到
    // {
    //   path: '/calendar',
    //   name: 'calendar',
    //   meta: {
    //     title: '签到概览',
    //     hidden: true,
    //     moduleName: 'calendar',
    //   },
    //   redirect: '/calendar/survey',
    //   component: Main,
    //   children: [
    //     {
    //       path: '/calendar/survey',
    //       name: 'survey',
    //       meta: {
    //         title: '签到概览',
    //         parentPath: '/marketing',
    //         moduleName: 'calendar',
    //         icon: 'icon_coupons_data',
    //         permission: ['view'],
    //       },
    //       component: () => import('@/views/marketing/calendar/survey.vue'),
    //     },
    //     {
    //       path: '/calendar/rule',
    //       name: 'rule',
    //       meta: {
    //         title: '签到规则',
    //         parentPath: '/marketing',
    //         moduleName: 'calendar',
    //         icon: 'icon_qiandao_guize',
    //         permission: ['view'],
    //       },
    //       component: () => import('@/views/marketing/calendar/rule.vue'),
    //     },
    //     {
    //       path: '/calendar/record',
    //       name: 'record',
    //       meta: {
    //         title: '签到记录',
    //         parentPath: '/marketing',
    //         moduleName: 'calendar',
    //         icon: 'icon_qiandao_jilu',
    //         permission: ['view'],
    //       },
    //       component: () => import('@/views/marketing/calendar/record.vue'),
    //     },
    //   ],
    // },

    // 自提门店
    // {
    //   path: '/selffetch',
    //   name: 'selffetch',
    //   meta: {
    //     title: '自提门店',
    //     hidden: true,
    //     moduleName: 'selffetch',
    //   },
    //   redirect: '/selffetch/selffetch_shop',
    //   component: Main,
    //   children: [
    //     {
    //       path: '/selffetch/selffetch_order',
    //       name: 'selffetch_order',
    //       meta: {
    //         title: '核销订单',
    //         parentPath: '/marketing',
    //         moduleName: 'selffetch',
    //         icon: 'icon_hexiao_order',
    //         permission: ['view'],
    //       },
    //       component: () => import('@/views/marketing/selffetch/order.vue'),
    //     },
    //     {
    //       path: '/selffetch/selffetch_shop',
    //       name: 'selffetch_shop',
    //       meta: {
    //         title: '自提门店',
    //         parentPath: '/marketing',
    //         moduleName: 'selffetch',
    //         icon: 'icon_ziti_store',
    //         permission: ['view'],
    //       },
    //       component: () => import('@/views/marketing/selffetch/shop.vue'),
    //     },
    //     {
    //       path: '/selffetch/selffetch_shop_edit',
    //       name: 'selffetch_shop_edit',
    //       meta: {
    //         title: '编辑自提门店',
    //         hidden: true,
    //         parentPath: '/marketing',
    //         prevPath: '/selffetch/selffetch_shop',
    //         moduleName: 'selffetch',
    //       },
    //       component: () => import('@/views/marketing/selffetch/shop_edit.vue'),
    //     },
    //     {
    //       path: '/selffetch/selffetch_verifier',
    //       name: 'selffetch_verifier',
    //       meta: {
    //         title: '核销员',
    //         parentPath: '/marketing',
    //         moduleName: 'selffetch',
    //         icon: 'icon_hexiao_member',
    //         permission: ['view'],
    //       },
    //       component: () => import('@/views/marketing/selffetch/verifier.vue'),
    //     },
    //     {
    //       path: '/selffetch/selffetch_verifier_edit',
    //       name: 'selffetch_verifier_edit',
    //       meta: {
    //         title: '编辑核销员',
    //         hidden: true,
    //         parentPath: '/marketing',
    //         prevPath: '/selffetch/selffetch_verifier',
    //         moduleName: 'selffetch',
    //       },
    //       component: () =>
    //         import('@/views/marketing/selffetch/verifier_edit.vue'),
    //     },
    //   ],
    // },

    // 消息通知
    {
        path: '/sms',
        name: 'sms',
        meta: {
            title: '通知买家',
            hidden: true,
            moduleName: 'sms',
        },
        redirect: '/sms/buyers',
        component: Main,
        children: [
            {
                path: '/sms/buyers',
                name: 'sms_buyers',
                meta: {
                    title: '通知买家',
                    parentPath: '/marketing',
                    moduleName: 'sms',
                    icon: 'icon_notice_buyer',
                },
                component: Blank,
                redirect: '/sms/buyers/buyers',
                children: [
                    {
                        path: '/sms/buyers/buyers',
                        name: 'sms_buyers_buyers',
                        meta: {
                            title: '业务通知',
                            parentPath: '/marketing',
                            moduleName: 'sms',
                            permission: ['view'],
                        },
                        component: () => import('@/views/marketing/sms/buyers/buyers.vue'),
                    },
                    {
                        path: '/sms/buyers/setting',
                        name: 'buyers_setting',
                        meta: {
                            hidden: true,
                            title: '通知买家设置',
                            parentPath: '/marketing',
                            moduleName: 'sms',
                        },
                        component: () => import('@/views/marketing/sms/buyers/setting.vue'),
                    },
                    {
                        path: '/sms/buyers/business',
                        name: 'business',
                        meta: {
                            title: '验证码',
                            parentPath: '/marketing',
                            moduleName: 'sms',
                            permission: ['view'],
                        },
                        component: () =>
                            import('@/views/marketing/sms/buyers/business.vue'),
                    },
                ],
            },
            {
                path: '/sms/seller',
                name: 'seller',
                meta: {
                    title: '卖家通知',
                    parentPath: '/marketing',
                    moduleName: 'sms',
                    icon: 'icon_notice_seller',
                    permission: ['view'],
                },
                component: () => import('@/views/marketing/sms/seller.vue'),
            },
            {
                path: '/sms/sms',
                name: 'sms_sms',
                meta: {
                    title: '短信设置',
                    parentPath: '/marketing',
                    moduleName: 'sms',
                    icon: 'icon_notice_mail',
                    permission: ['view'],
                },
                component: () => import('@/views/marketing/sms/sms.vue'),
            },
            {
                path: '/sms/sms_edit',
                name: 'sms_edit',
                meta: {
                    hidden: true,
                    title: '短信设置详情',
                    parentPath: '/marketing',
                    prevPath: '/sms/sms',
                    moduleName: 'sms',
                },
                component: () => import('@/views/marketing/sms/sms_edit.vue'),
            },
        ],
    },

    // 商城公告
    {
        path: '/notice',
        name: 'notice',
        meta: {
            hidden: true,
            title: '商城公告',
            moduleName: 'notice',
        },
        redirect: '/notice',
        component: Main,
        children: [
            {
                path: '/notice/lists',
                name: 'notice_lists',
                meta: {
                    title: '公告管理',
                    parentPath: '/marketing',
                    moduleName: 'notice',
                    icon: 'icon_notice',
                    permission: ['view'],
                },
                component: () => import('@/views/marketing/notice/lists.vue'),
            },
            {
                path: '/notice/notice_edit',
                name: 'notice_edit',
                meta: {
                    hidden: true,
                    title: '添加公告',
                    parentPath: '/notice',
                    prevPath: '/notice/lists',
                    moduleName: 'notice',
                },
                component: () => import('@/views/marketing/notice/notice_edit.vue'),
            },
        ],
    },

    // 在线客服
    {
        path: '/service',
        name: 'service',
        meta: {
            hidden: true,
            title: '在线客服',
            moduleName: 'service',
        },
        redirect: '/service',
        component: Main,
        children: [
            {
                path: '/service',
                name: 'service2',
                meta: {
                    title: '在线客服',
                    parentPath: '/marketing',
                    moduleName: 'service',
                    icon: 'icon_kefu_comments',
                    permission: ['view'],
                },
                component: () => import('@/views/marketing/service.vue'),
            },
        ],
    },// 文章资讯
    {
        path: '/article',
        name: 'article',
        meta: {
            hidden: true,
            title: '文章资讯',
            moduleName: 'article'
        },
        redirect: '/article',
        component: Main,
        children: [{
            path: '/article/lists',
            name: 'article_lists',
            meta: {
                title: '资讯管理',
                parentPath: '/marketing',
                moduleName: 'article',
                icon: 'icon_notice',
                permission: ['view']
            },
            component: () => import('@/views/marketing/article/lists.vue')
        }, {
            path: '/article/article_edit',
            name: 'article_edit',
            meta: {
                hidden: true,
                title: '资讯管理',
                parentPath: '/article',
                prevPath: '/article/lists',
                moduleName: 'article',
            },
            component: () => import('@/views/marketing/article/article_edit.vue')
        }, {
            path: '/article/category_lists',
            name: 'category_lists',
            meta: {
                title: '资讯分类',
                parentPath: '/marketing',
                moduleName: 'article',
                icon: 'icon_notice',
                permission: ['view']
            },
            component: () => import('@/views/marketing/article/category_lists.vue')
        }]
    }

    // 砍价活动
    // {
    //   path: '/bargain',
    //   name: 'bargain',
    //   meta: {
    //     hidden: true,
    //     title: '砍价活动',
    //     moduleName: 'bargain',
    //   },
    //   redirect: '/bargain/lists',
    //   component: Main,
    //   children: [
    //     {
    //       path: '/bargain/lists',
    //       name: 'bargain',
    //       meta: {
    //         title: '砍价活动',
    //         parentPath: '/marketing',
    //         moduleName: 'bargain',
    //         icon: 'icon_kanjia',
    //         permission: ['view'],
    //       },
    //       component: () => import('@/views/marketing/bargain/lists.vue'),
    //     },
    //     {
    //       path: '/bargain/bargain_edit',
    //       name: 'bargain_edit',
    //       meta: {
    //         hidden: true,
    //         title: '砍价活动',
    //         parentPath: '/marketing',
    //         prevPath: '/bargain/lists',
    //         moduleName: 'bargain',
    //       },
    //       component: () => import('@/views/marketing/bargain/bargain_edit.vue'),
    //     },
    //     {
    //       path: '/bargain/bargain_record',
    //       name: 'bargain_record',
    //       meta: {
    //         title: '砍价记录',
    //         parentPath: '/marketing',
    //         icon: 'icon_order_guanli',
    //         moduleName: 'bargain',
    //         permission: ['view'],
    //       },
    //       component: () => import('@/views/marketing/bargain/bargain_record.vue'),
    //     },
    //   ],
    // },

    // 拼团活动
    // {
    //   path: '/combination',
    //   name: 'combination',
    //   meta: {
    //     hidden: true,
    //     title: '拼团活动',
    //     moduleName: 'combination',
    //   },
    //   redirect: '/combination/lists',
    //   component: Main,
    //   children: [
    //     {
    //       path: '/combination/survey',
    //       name: 'combination',
    //       meta: {
    //         hidden: true,
    //         title: '拼团概览',
    //         parentPath: '/marketing',
    //         moduleName: 'combination',
    //         icon: 'icon_pintuan_data',
    //         permission: ['view'],
    //       },
    //       component: () => import('@/views/marketing/combination/survey.vue'),
    //     },
    //     {
    //       path: '/combination/lists',
    //       name: 'combination',
    //       meta: {
    //         title: '拼团活动',
    //         parentPath: '/marketing',
    //         moduleName: 'combination',
    //         icon: 'icon_pintuan2',
    //         permission: ['view'],
    //       },
    //       component: () => import('@/views/marketing/combination/lists.vue'),
    //     },
    //     {
    //       path: '/combination/edit',
    //       name: 'edit',
    //       meta: {
    //         hidden: true,
    //         title: '拼团活动',
    //         prevPath: '/combination/lists',
    //         moduleName: 'combination',
    //       },
    //       component: () => import('@/views/marketing/combination/edit.vue'),
    //     },
    //     {
    //       path: '/combination/record',
    //       name: 'record',
    //       meta: {
    //         title: '拼团记录',
    //         icon: 'icon_order_guanli',
    //         parentPath: '/marketing',
    //         moduleName: 'combination',
    //       },
    //       component: () => import('@/views/marketing/combination/record.vue'),
    //     },
    //   ],
    // },

    // 限时秒杀
    // {
    //   path: '/seckill',
    //   name: 'seckill',
    //   meta: {
    //     hidden: true,
    //     title: '限时秒杀',
    //     moduleName: 'seckill',
    //   },
    //   redirect: '/seckill/lists',
    //   component: Main,
    //   children: [
    //     {
    //       path: '/seckill/lists',
    //       name: 'seckill',
    //       meta: {
    //         title: '秒杀活动',
    //         parentPath: '/marketing',
    //         moduleName: 'seckill',
    //         icon: 'icon_seckill',
    //         permission: ['view'],
    //       },
    //       component: () => import('@/views/marketing/seckill/lists.vue'),
    //     },
    //     {
    //       path: '/seckill/edit',
    //       name: 'seckill_edit',
    //       meta: {
    //         hidden: true,
    //         title: '秒杀活动',
    //         parentPath: '/marketing',
    //         prevPath: '/seckill/lists',
    //         moduleName: 'seckill',
    //       },
    //       component: () => import('@/views/marketing/seckill/edit.vue'),
    //     },
    //   ],
    // },
]

export default routes
